import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container } from "react-bootstrap"

const Condiciones = () => (
  <Layout>
    <Seo title="Términos y condiciones" />
    <div className="text-center pt-6 pb-6">
      <Container>
        <h1>Términos y condiciones</h1>
      </Container>
    </div>

    <Container>
      <div className="text-justify pb-5">
        <p className="s-title">GENERAL</p>
        <p>
          Los Términos de servicio (TOS) y la Política de uso aceptable (AUP) de
          PuntoHost.com se actualizan regularmente. Como cliente nuestro, usted
          acepta seguir estos términos, por lo tanto, le pedimos que revise
          estos términos periódicamente y se comunique con nosotros si necesita
          alguna aclaración.
        </p>
        <p>
          Tenga en cuenta que nos reservamos el derecho de respetar estos
          términos, y nos reservamos el derecho de negarnos a hacer negocios.
        </p>

        <p className="s-title">1. PROVISIÓN</p>
        <p>
          1.1 Todos los servicios de hosting compartido, hosting revendedor y
          audio streaming se aprovisionan automática e instantáneamente,
          suponiendo que su orden y solicitud de servicios no se activan
          automáticamente, es posible que se deba a algún mecanismo de revisión
          de fraude que podamos emplear por razones de seguridad. Dichos
          pedidos, así como los pedidos de servidores vps o dedicados requieren
          una revisión manual y pueden tardar hasta 24 horas en configurarse.
        </p>
        <p>
          1.2 Esta garantía sólo aplica a nuestro servicios de alojamiento web y
          audio streaming. Los servicios de registro de dominios y cualquier
          otro quedan completamente excluidos, por lo que si usted adquirió un
          plan de hosting más dominio incluido, se le devolverá el monto total
          pagado, menos el valor del dominio y cualquier otro servicio adicional
          ya que esto se cancela una vez al año a nuestro registrador principal
          de dominios y/o servicios.{" "}
        </p>

        <p className="s-title">2. APOYO INIGUALABLE</p>
        <p>
          2.1 De forma predeterminada, los servicios de PuntoHost.com no están
          administrados, lo que significa que se proporciona asistencia técnica
          para problemas que escapan a su control. Esto incluye pero no se
          limita a: problemas de red, problemas de hardware, etc.
        </p>
        <p>
          2.2 Sin embargo, siempre asistiremos a todos los clientes de la mejor
          manera posible independientemente de la solicitud. Además, los planes
          de Administración de servidor están disponibles para los clientes que
          solicitan un espectro más amplio de soporte individual.
        </p>

        <p className="s-title">3. PAGOS</p>
        <p>
          3.1 Aceptamos pagos a través de PayPal, tarjetas de crédito y
          transferencias/pago movil (aplica para Venezuela).
        </p>

        <p className="s-title">4. REEMBOLSOS</p>
        <p>
          4.1 Ofrecemos una garantía de devolución de dinero (80% de su dinero,
          quedando el restante por gastos operativos) de 7 días. Todas las
          solicitudes de reembolso enviadas después de este período de tiempo se
          evalúan caso por caso. Ciertamente, deseamos trabajar con cualquier
          cliente que tenga problemas con nuestros servicios.
        </p>
        <p>
          4.2 Si paga con PayPal, al registrarse, tendrá la opción de elegir si
          desea pagar manualmente cada ciclo de facturación o si desea
          configurar una suscripción periódica. La mayoría de los clientes
          prefieren los pagos automáticos y los pagos con más de 5 días de
          retraso se cobran automáticamente con un 10% de recargo. A los
          clientes que no tienen un historial de pagos tardíos se les puede
          eximir de este cargo a pedido (entendemos que suceden cosas).
        </p>
        <p>
          4.3 Solo las cuentas de “primera vez” son elegibles para un reembolso.
          Si usted ya tuvo una cuenta con nosotros antes, canceló y se registra
          nuevamente o si abre una segunda cuenta, no será elegible para un
          reembolso.
        </p>

        <p className="s-title">5. CORREO ELECTRÓNICO NO SOLICITADO / SPAM</p>
        <p>
          5.1 PuntoHost.com no tolera el correo electrónico de UCE o SPAM. Por
          favor respeta esta regla y mantén nuestra red limpia. Nos reservamos
          el derecho de suspender o cancelar, con o sin previo aviso la
          cuenta/cuentas que violen esta política.
        </p>

        <p className="s-title">6. DOMINIO GRATIS</p>
        <p>
          6.1 Al registrar un dominio gratis este solo podrá ser usado en los
          servidores de PuntoHost, si el cliente desea apuntar el dominio a otro
          proveedor deberá cancelar el costo de registro al precio vigente del
          momento.
        </p>

        <p className="s-title">7. REGISTRO DE DOMINIOS VENEZOLANOS (.ve).</p>
        <p>
          7.1 PuntoHost NO registrara dominios nacionales (.ve) sin uno de
          nuestros planes de hosting asociados, así como el tiempo de registro
          puede demorar hasta 15 días hábiles en procesar por parte de Nic
          Venezuela.
        </p>

        <p className="s-title">8. RENOVACIÓN DE DOMINIOS INTERNACIONALES</p>
        <p>
          8.1 PuntoHost NO tendrá obligación de renovar dominios internacionales
          con pago en Bolívares si este cuenta con menos de 15 días antes de su
          vencimiento, en caso de que el dominio cuenta con menos de 15 días
          antes de su vencimiento usted deberá pagar el monto actual de
          renovación de dominios en USD (Dólares Americanos).{" "}
        </p>

        <p className="s-title">9. SUSPENSIONES</p>
        <p>
          9.1 Diariamente se realiza escaneo de todas las cuentas de Hosting, en
          caso de contener malware o cualquier código malicioso será suspendida
          y los archivos eliminados. En caso de SPAM se levantará la suspensión
          según el grado de compromiso y por única vez, si se persiste el
          servicio será dado de baja sin opción a reembolso.
        </p>

        <p className="s-title">10. LO PERMITIDO Y LO NO PERMITIDO</p>
        <p>
          10.1 En PuntoHost.com queremos que sea sencillo para nosotros y para
          usted. La respuesta corta es que, mientras lo que estás haciendo no
          sea ilegal, ¡está bien para usar!
        </p>
        <p>
          10.2 No permitimos actividades ilegales o perjudiciales, como
          pornografía infantil, correo no deseado, envío de ataques DDoS,
          extracción de criptomonedas, torrents, warez o virtualización anidada.
        </p>

        <p className="s-title">11. COPIA DE SEGURIDAD DE DATOS</p>
        <p>
          11.1 Salvo que PuntoHost haya aceptado expresamente por escrito lo
          contrario, los clientes son los únicos y totalmente responsables,
          PuntoHost no es responsable en absoluto de la gestión y respaldo de
          todos los datos de los clientes y todas las actualizaciones,
          actualizaciones y parches de cualquier software que los clientes usen
          en conexión con los servicios de PuntoHost. Los servidores de
          PuntoHost tampoco se usan como almacenamiento de respaldo y solo son
          para alojar archivos directamente relacionados con su sitio web.
        </p>

        <p className="s-title">12. COPIAS DE SEGURIDAD MENSUAL</p>
        <p>
          12.1 PuntoHost realiza copias de seguridad mensuales de la cuenta
          completa. Las copias de seguridad mensuales incluyen la copia de
          seguridad de todos los datos de cuenta, cuentas de correo electrónico
          y bases de datos MySQL (excepto la papelera de administrador de
          archivos y correos, estos son vaciados semanalmente). Las copias de
          seguridad semanales son solo para fines de recuperación de desastres
          de PuntoHost. Las cuentas de revendedor y sus cuentas de cliente se
          incluyen en las cuentas que respaldamos, sin embargo, las copias de
          seguridad no se incluyen como parte de su servicio y los revendedores
          son responsables de sus propias copias de seguridad y no deben confiar
          en las copias de seguridad que realiza PuntoHost. No hay garantía de
          datos expresados o implícitos. Las cuentas solo se incluyen en copias
          de seguridad mensuales de 10 GB o menos.
        </p>

        <p className="s-title">13. LÍMITE DE LAS GARANTÍAS.</p>
        <p>
          EL USO DEL SERVICIO ES EXCLUSIVAMENTE A RIESGO DEL CLIENTE. EL
          SERVICIO SE OFRECE EN SU ESTADO ACTUAL, SIN GARANTÍAS DE NINGÚN TIPO,
          YA SEAN EXPRESAS, IMPLÍCITAS O LEGALES, INCLUYENDO SIN LIMITACIÓN
          ALGUNA, GARANTÍAS DE COMERCIABILIDAD O ADECUACIÓN PARA USOS
          ESPECÍFICOS. EN LA MEDIDA MÁXIMA PERMITIDA POR LA LEY, EL CLIENTE
          RENUNCIA A TODA GARANTÍA DE SEGURIDAD, CONFIABILIDAD, PUNTUALIDAD Y
          RENDIMIENTO DEL SERVICIO. EN LA MEDIDA MÁXIMA PERMITIDA POR LA LEY, EL
          CLIENTE RENUNCIA A TODA GARANTÍA RESPECTO A OTROS SERVICIOS O BIENES
          RECIBIDOS A TRAVÉS O ANUNCIADOS A TRAVÉS DE ESTE SERVICIO. BAJO
          NINGUNA CIRCUNSTANCIA "PUNTOHOST.COM" SERÁ RESPONSABLE ANTE UN CLIENTE
          POR EL USO INDEBIDO DEL SERVICIO POR UN CLIENTE. “PUNTOHOST.COM ” NO
          RESPONDERÁ POR LOS DAÑOS DIRECTOS, INDIRECTOS, PATRIMONIALES, MORALES
          (AUNQUE SE HAYA NOTIFICADO A "PUNTOHOST.COM"SOBRE LA POSIBILIDAD DE
          TALES DAÑOS). TAL LIMITACIÓN DE RESPONSABILIDAD SE APLICARÁ
          INDEPENDIENTEMENTE DEL HECHO DE SI TALES DAÑOS SURGEN DEL USO O USO
          INDEBIDO DEL SERVICIO, DE LA DEPENDENCIA EN EL SERVICIO, DE LA
          INCAPACIDAD DE USAR EL SERVICIO, O DE LA INTERRUPCIÓN, SUSPENSIÓN O
          TERMINACIÓN DEL SERVICIO OFRECIDO (INCLUYENDO DAÑOS INCURRIDOS POR
          TERCEROS). TAL LIMITACIÓN SE APLICARÁ RESPECTO A DAÑOS INCURRIDOS POR
          MOTIVO DE OTROS SERVICIOS O BIENES RECIBIDOS A TRAVÉS DE ESTE
          SERVICIO, ASÍ COMO POR MOTIVO DE CUALQUIER INFORMACIÓN O ASESORAMIENTO
          RECIBIDOS A TRAVÉS O ANUNCIADOS POR "PUNTOHOST.COM" CON OCASIÓN DE
          ESTE SERVICIO. TALES LIMITACIONES SE APLICARAN EN LA MÁXIMA MEDIDA
          PERMITIDA POR LEY. “PUNTOHOST.COM ” NO HACE NINGUNA DECLARACIÓN O
          GARANTÍAS IMPLÍCITAS O EXPRESAS ACERCA DE LA CONFIABILIDAD O
          ACCESIBILIDAD DE LAS PÁGINAS DE CLIENTES O LAS FACILIDADES DE
          ALMACENAMIENTO PERTINENTES. "PUNTOHOST.COM" NO ES RESPONSABLE POR
          CUALQUIER DAÑO CAUSADO POR LA PERDIDA DE ACCESO, LA CANCELACIÓN, LA
          OMISIÓN DE ALMACENAR O HACER COPIAS O MODIFICACIONES DE LA INFORMACIÓN
          QUE SE OBTENGA HACIENDO USO DEL SERVICIO; LOS CLIENTES INDIVIDUALES
          SON RESPONSABLES DE CONTROLAR Y CREAR COPIAS DE SUS PAGINAS O DE LA
          INFORMACIÓN QUE OBTENGAN.
        </p>

        <p className="s-title">14. CAMBIOS DE FACTURACIÓN/PRECIOS</p>
        <p>
          14.1 Las políticas y precios de PuntoHost están sujetos a cambios sin
          previo aviso. Cualquier cambio de precio entrará en vigencia en el
          próximo ciclo de facturación.
        </p>

        <p className="s-title">¿PREGUNTAS? ¿ACLARACIÓN?</p>
        <p>
          ¡Siéntete libre de hablar con nosotros! Somos amigables y flexibles,
          trabaje con nosotros y trabajaremos con usted :)
        </p>
        <p>
          Póngase en contacto con nosotros en si tiene alguna pregunta adicional
        </p>
      </div>
    </Container>
  </Layout>
)

export default Condiciones
